import React from "react";
import { motion } from "framer-motion";
import { slowEase } from "../styles/animations/framer_transitions";

const PageLanding = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="w-full grid place-content-center mt-32 mb-32 sm:mb-96 sm:my-64 select-none">
        <motion.h1
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            ...slowEase,
          }}
          className="text-4xl sm:text-7xl font-display"
        >
          {children}
        </motion.h1>
      </div>
    </>
  );
};

export default PageLanding;
