import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import { slideInDown } from "../styles/animations/framer_animations";
import PageLanding from "../components/PageLanding";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const WorkExperienceTemplate = (props: IProps) => {
  const { contentfulWorkExperience } = props.data;
  const {
    companyName,
    jobTitle,
    startDate,
    endDate,
    logo,
    location,
    description,
    technologiesUsed,
    websiteUrl,
  } = contentfulWorkExperience;

  const technologyContainerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3,
        delaychildren: 0.5,
      },
    },
  };

  return (
    <>
      <PageLanding>
        <motion.img
          className="h-32 brightness-0 dark:invert grayscale mx-auto mb-5"
          src={logo.file.url}
          alt={`${companyName} logo`}
        />
        <h1 className="font-display text-4xl sm:text-7xl text-center mb-10 sm:mb-20 antialiased select-none">
          {websiteUrl ? (
            <OutboundLink href={`${websiteUrl}`}>{companyName}</OutboundLink>
          ) : (
            companyName
          )}
        </h1>
        <h2 className="font-body text-lg uppercase text-center antialiased select-none">
          {location}
        </h2>
        <p className="font-body text-lg text-center antialiased select-none">
          {startDate} to {endDate ? endDate : "Present"}
        </p>
      </PageLanding>

      <div className="grid grid-cols-1 sm:grid-cols-2 my-20">
        <div className="p-2 sm:p-10 mb-20 sm:mb-0 select-none leading-loose text-xl grid place-items-center antialiased">
          {description.description}
        </div>
        <div className="p-10 bg-stone-light">
          <h2 className="font-body uppercase text-xl text-center mb-10 antialiased text-black">
            Toolbelt
          </h2>
          <motion.div
            className="grid grid-cols-2 gap-10 place-items-center"
            variants={technologyContainerVariants}
          >
            {technologiesUsed.map((technology: Technology, index: Number) => {
              const { name, logo } = technology;
              return (
                <>
                  <OutboundLink href={`${technology.link}`} target="_blank">
                    <motion.img
                      className="h-10 block mx-auto brightness-0 invert-0 grayscale mb-2"
                      src={logo.file.url}
                      alt={name}
                      key={index.toString()}
                      variants={slideInDown}
                    />
                    <div className="text-center text-black font-body uppercase">
                      {technology.name}
                    </div>
                  </OutboundLink>
                </>
              );
            })}
          </motion.div>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  query getWorkExperience($id: String) {
    contentfulWorkExperience(id: { eq: $id }) {
      id
      location
      jobTitle
      description {
        description
      }
      startDate(formatString: "MMMM YYYY")
      endDate(formatString: "MMMM YYYY")
      companyName
      websiteUrl
      logo {
        file {
          url
        }
      }
      technologiesUsed {
        name
        link
        logo {
          file {
            url
          }
        }
      }
    }
  }
`;

interface Technology {
  id: string;
  name: string;
  link: string;
  logo: {
    publicUrl: string;
    file: {
      url: string;
    };
  };
}

interface IProps {
  data: { contentfulWorkExperience: IExperience };
}

interface IExperience {
  companyName: string;
  jobTitle: string;
  startDate: string;
  endDate?: string;
  logo: any;
  location: string;
  websiteUrl: string;
  id: string;
  description: { description: string };
  technologiesUsed: Technology[];
}

export default WorkExperienceTemplate;
